import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';
import { PersonOutlined } from '@mui/icons-material';
import { QueryObserverResult } from '@tanstack/react-query';
import { FormCard, FormContainer, FormInput } from 'components';
import { campaigns, CampaignTypes } from 'api';
import { getFieldsWithValues, getModifiedFields } from 'helpers/getFormFields';
import { useApiRequest, useFiltersSearchParams } from 'hooks';
import BRANDS from 'constants/brands';
import COUNTRIES from 'constants/countries';
import CAMPAIGNS from 'constants/campaigns';
import { appLinks } from 'routes/links';
import { useStoreContext } from 'store';
import ImageUpload from './ImageUpload';

interface Props {
  id: string | undefined;
  initialData: CampaignTypes.ItemCreateParams;
  refetch: () => Promise<QueryObserverResult>;
  pictureUrl?: CampaignTypes.ItemData['picture_url'];
  drawer?: boolean;
  drawerTitle?: string;
}

const Form: React.FC<Props> = (props: Props): JSX.Element => {
  const { initialData, id, refetch, pictureUrl, drawer, drawerTitle } = props;
  const navigate = useNavigate();
  const {
    state: { refetchTableData },
  } = useStoreContext();

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, isDirty, isSubmitting },
    watch,
  } = useForm<CampaignTypes.ItemCreateParams>({
    defaultValues: initialData,
  });

  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const { requestFn } = useApiRequest(
    id ? (data) => campaigns.update(id, data) : campaigns.create,
  );

  const startDate = watch('start_date');
  const endDate = watch('end_date');

  const onSubmit = useCallback(
    async (data: CampaignTypes.ItemCreateParams) => {
      const values = id
        ? getModifiedFields(dirtyFields, data)
        : getFieldsWithValues(data);

      const res = await requestFn({
        args: values,
        successMessage: `Campaign ${id ? 'updated' : 'created'}`,
      });

      if (res) {
        if (drawer) {
          updateFiltersSearchParams({
            campaignId: undefined,
            drawerMode: undefined,
          });

          if (refetchTableData) {
            await refetchTableData();
          }
        } else {
          navigate(appLinks.campaigns.details.as(res.campaign_id), {
            replace: true,
            state: {
              from: id ? 'edit' : 'create',
            },
          });
        }
      }
    },
    [
      id,
      dirtyFields,
      requestFn,
      drawer,
      updateFiltersSearchParams,
      refetchTableData,
      navigate,
    ],
  );

  return (
    <FormContainer
      variant={drawer ? 'drawer' : 'page'}
      drawerTitle={drawerTitle}
      pageLink={id ? appLinks.campaigns.edit.as(id) : undefined}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      disabled={!!id && !isDirty}
    >
      <FormCard title="General information" avatar={<PersonOutlined />}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="name"
              inputType="input"
              label="Name"
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="type"
              inputType="select"
              label="Type"
              options={CAMPAIGNS.TYPE_OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="status"
              inputType="select"
              label="Status"
              options={CAMPAIGNS.STATUS_OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="brands"
              inputType="select"
              label="Brands"
              options={BRANDS.OPTIONS}
              SelectProps={{
                multiple: true,
              }}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="country_teams"
              inputType="select"
              label="Country teams"
              options={COUNTRIES.OPTIONS}
              SelectProps={{
                multiple: true,
              }}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              inputType="datepicker"
              name="start_date"
              label="Start Date"
              maxDate={dayjs.utc(endDate).subtract(1, 'days')}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              inputType="datepicker"
              name="end_date"
              label="End Date"
              minDate={dayjs.utc(startDate).add(1, 'days')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="description"
              inputType="textarea"
              label="Description"
              rows={4}
            />
          </Grid>
          {id && (
            <Grid item xs={12}>
              <ImageUpload
                id="t6ngp"
                value={pictureUrl || ''}
                onUploaded={refetch}
              />
            </Grid>
          )}
        </Grid>
      </FormCard>
    </FormContainer>
  );
};

export default Form;
