import React from 'react';
import {
  TextField,
  MenuItem,
  Chip,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { InfoOutlined } from '@mui/icons-material';
import FormInputProps from './FormInputProps';

type Props<T extends FieldValues = any> = Omit<
  FormInputProps.SelectInput,
  'inputType'
> &
  UseControllerProps<T>;

const SelectedItems = ({ selected }: { selected: string[] }) => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
    {selected.map((value) => (
      <Chip key={value} label={value} color="primary" size="small" />
    ))}
  </Box>
);

const SelectInput: React.FC<Props> = (props: Props) => {
  const {
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    placeholder,
    label,
    options,
    onChange,
    children,
    tooltip,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const initialValue = rest.SelectProps?.multiple ? [] : '';

  return (
    <TextField
      select
      inputRef={field.ref}
      name={field.name}
      value={field.value || initialValue}
      onChange={onChange ? (e) => onChange(e, field.onChange) : field.onChange}
      onBlur={field.onBlur}
      error={!!error}
      helperText={error && error.message}
      label={
        tooltip ? (
          <Typography display="flex" gap={1}>
            {label}
            <Tooltip title={tooltip}>
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </Typography>
        ) : (
          label
        )
      }
      placeholder={placeholder}
      {...rest}
      SelectProps={{
        displayEmpty: true,
        ...rest.SelectProps,
        MenuProps: {
          ...rest.SelectProps?.MenuProps,
          sx: {
            maxHeight: '50vh',
          },
        },
        renderValue: rest.SelectProps?.multiple
          ? // eslint-disable-next-line react/no-unstable-nested-components
            (selected) => {
              if ((selected as []).length === 0) {
                return `Select ${label}`;
              }

              return <SelectedItems selected={selected as string[]} />;
            }
          : undefined,
      }}
    >
      <MenuItem value="" disabled>
        {`Select ${label}`}
      </MenuItem>
      {children ||
        options.map((o) => (
          <MenuItem key={o.value} value={o.value} disabled={o.disabled}>
            {o.label}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default SelectInput;
