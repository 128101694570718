import * as React from 'react';
import {
  alpha,
  Avatar,
  Box,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { InfluencerTypes } from 'api';
import INFLUENCERS from 'constants/influencers';
import { useMemo } from 'react';
import { CloseOutlined, RestoreOutlined } from '@mui/icons-material';
import { AutocompleteSearchItem } from 'api/types/common';
import { getUserInitials } from 'helpers/common';
import VerifiedIcon from '@mui/icons-material/Verified';
import { BrandStatusEnum } from 'api/types/Brands';

interface Props extends React.HTMLAttributes<HTMLLIElement> {
  option: AutocompleteSearchItem;
  onRemove: (id: string) => void;
}

const AutocompleteOption = (props: Props): JSX.Element => {
  const { option, onRemove, ...rest } = props;

  const platforms = useMemo(() => {
    if (option.platforms) {
      return Object.keys(option.platforms).map((key) => {
        const platform =
          INFLUENCERS.PLATFORM_LOWERCASE[
            key as Lowercase<InfluencerTypes.PlatformEnum>
          ];
        return { platform, Icon: INFLUENCERS.PLATFORM_ICONS[platform] };
      });
    }

    return [];
  }, [option.platforms]);

  const handleRemove = (event: React.MouseEvent) => {
    event.stopPropagation();

    onRemove(option._id.$oid);
  };

  const isBlacklisted =
    Array.isArray(option.status) &&
    option.status.includes(BrandStatusEnum.Blacklist);

  return (
    <li {...rest}>
      <ListItemAvatar>
        <Avatar
          sx={(theme) => ({
            backgroundColor: isBlacklisted
              ? alpha(theme.palette.error.main, 0.1)
              : alpha(theme.palette.primary.main, 0.1),
            color: isBlacklisted
              ? theme.palette.error.main
              : theme.palette.primary.main,
          })}
        >
          {getUserInitials(option.name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box display="flex" alignItems="center" columnGap={1}>
            <Typography color={isBlacklisted ? 'error' : undefined}>
              {option.name || '-'}
            </Typography>
            {option.is_verified && (
              <VerifiedIcon color="primary" fontSize="small" />
            )}
          </Box>
        }
        secondary={
          <Box>
            {isBlacklisted ? (
              <Typography color="error">Influencer is in blacklist</Typography>
            ) : undefined}
            {platforms.length > 0 && (
              <Stack
                direction="row"
                columnGap={1}
                alignItems="center"
                mt={0.75}
              >
                {platforms.map(({ platform, Icon }) => (
                  <Icon
                    key={platform}
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                      fontSize: '16px',
                    })}
                  />
                ))}
              </Stack>
            )}
          </Box>
        }
      />
      {option.historyItem && (
        <ListItemIcon
          onClick={handleRemove}
          sx={{
            minWidth: 'unset',
            '& .restore': {
              display: 'block',
            },
            '& .remove': {
              display: 'none',
            },
            '&:hover': {
              cursor: 'pointer',
              '& .restore': {
                display: 'none',
              },
              '& .remove': {
                display: 'block',
              },
            },
          }}
        >
          <RestoreOutlined className="restore" />
          <CloseOutlined className="remove" />
        </ListItemIcon>
      )}
    </li>
  );
};

export default AutocompleteOption;
