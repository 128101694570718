import React, { useCallback } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { getIsoUtcDate } from 'helpers/dayJsFormats';
import { Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import FormInputProps from './FormInputProps';

type Props<T extends FieldValues = any> = Omit<
  FormInputProps.DateTimePickerInput,
  'inputType'
> &
  UseControllerProps<T>;

const DateTimePickerInput: React.FC<Props> = (props: Props) => {
  const {
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    TextFieldProps,
    onChange,
    onClose,
    tooltip,
    label,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const handleChange = useCallback(
    (value: Dayjs | null) => {
      try {
        field.onChange(value ? getIsoUtcDate(value) : undefined);
      } catch (e) {
        field.onChange(undefined);
      }
    },
    [field],
  );

  return (
    <DateTimePicker
      inputRef={field.ref}
      value={field.value ? dayjs.utc(field.value) : null}
      onChange={(value) =>
        onChange ? onChange(value, field.onChange) : handleChange(value)
      }
      closeOnSelect
      onClose={onClose ? () => onClose(field.value) : undefined}
      label={
        tooltip ? (
          <Typography display="flex" gap={1}>
            {label}
            <Tooltip title={tooltip}>
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </Typography>
        ) : (
          label
        )
      }
      {...rest}
      slotProps={{
        field: {
          clearable: true,
          onClear: () =>
            onChange ? onChange(null, field.onChange) : handleChange(null),
        },
        textField: {
          ...TextFieldProps,
          variant: 'outlined',
          name: field.name,
          onBlur: field.onBlur,
          error: !!error,
          helperText: error && error.message,
          InputProps: {
            notched: false,
          },
        },
      }}
    />
  );
};

export default DateTimePickerInput;
