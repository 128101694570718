import React, { useCallback, useMemo } from 'react';
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from 'helpers/formatters';
import {
  CardContent,
  CardHeader,
  Chip,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TrendingDownRounded, TrendingUpRounded } from '@mui/icons-material';
import { get } from 'lodash';
import { InfluencerTypes } from '../../../../../api';
import { ColorCard, TooltipChip } from '../../../../../components';
import { getInfluencerName } from '../../../../../helpers/common';

interface Props {
  influencer: InfluencerTypes.ItemData;
  comparedInfluencer?: InfluencerTypes.ItemData;
}

const InfluencerComparisonTable = (props: Props): JSX.Element | null => {
  const { influencer, comparedInfluencer } = props;

  const getTrend = useCallback(
    (key: keyof InfluencerTypes.ItemData | string) =>
      (get(influencer, key) || 0) >
      (comparedInfluencer ? get(comparedInfluencer, key) : 0)
        ? 'up'
        : 'down',
    [influencer, comparedInfluencer],
  );

  const tableFields = useMemo(
    () => [
      {
        key: 'avg_revenue',
        label: 'Avg Revenue',
        prevValue: formatCurrency(comparedInfluencer?.avg_revenue || 0),
        currentValue: formatCurrency(influencer.avg_revenue || 0),
        trend: getTrend('avg_revenue'),
      },
      {
        key: 'avg_budget',
        label: 'Avg Budget',
        prevValue: formatCurrency(comparedInfluencer?.avg_budget || 0),
        currentValue: formatCurrency(influencer.avg_budget || 0),
        trend: getTrend('avg_budget'),
      },
      {
        key: 'avg_cpm',
        label: 'Avg CPM',
        prevValue: formatNumber(comparedInfluencer?.avg_cpm || 0),
        currentValue: formatNumber(influencer.avg_cpm || 0),
        trend: getTrend('avg_cpm'),
      },
      {
        key: 'avg_cr',
        label: 'Avg CR',
        prevValue: formatPercent(comparedInfluencer?.avg_cr || 0),
        currentValue: formatPercent(influencer.avg_cr || 0),
        trend: getTrend('avg_cr'),
      },
      {
        key: 'avg_gross_profit',
        label: 'Avg Gross Profit',
        prevValue: formatCurrency(comparedInfluencer?.avg_gross_profit || 0),
        currentValue: formatCurrency(influencer.avg_gross_profit || 0),
        trend: getTrend('avg_gross_profit'),
      },
      {
        key: 'cpo',
        label: 'CPO',
        prevValue: formatCurrency(comparedInfluencer?.cpo || 0),
        currentValue: formatCurrency(influencer.cpo || 0),
        trend: getTrend('roi'),
      },
      {
        key: 'roi',
        label: 'ROI',
        prevValue: formatNumber(comparedInfluencer?.roi || 0),
        currentValue: formatNumber(influencer.roi || 0),
        trend: getTrend('roi'),
      },
      {
        key: 'total_gross_profit',
        label: 'Total Gross Profit',
        prevValue: formatCurrency(comparedInfluencer?.total_gross_profit || 0),
        currentValue: formatCurrency(influencer.total_gross_profit || 0),
        trend: getTrend('total_gross_profit'),
      },
      {
        key: 'total_revenue',
        label: 'Total Revenue',
        prevValue: formatCurrency(comparedInfluencer?.total_revenue || 0),
        currentValue: formatCurrency(influencer.total_revenue || 0),
        trend: getTrend('total_revenue'),
      },
      {
        key: 'total_spent',
        label: 'Total Spent',
        prevValue: formatCurrency(comparedInfluencer?.total_spent || 0),
        currentValue: formatCurrency(influencer.total_spent || 0),
        trend: getTrend('total_spent'),
      },
      {
        key: 'instagram_followers',
        label: 'Instagram Followers',
        prevValue: formatNumber(
          comparedInfluencer?.instagram?.followers_nb || 0,
        ),
        currentValue: formatNumber(influencer?.instagram?.followers_nb || 0),
        trend: getTrend('instagram.followers_nb'),
      },
      {
        key: 'instagram_following',
        label: 'Instagram Followings',
        prevValue: formatNumber(
          comparedInfluencer?.instagram?.followings_nb || 0,
        ),
        currentValue: formatNumber(influencer?.instagram?.followings_nb || 0),
        trend: getTrend('instagram.followings_nb'),
      },
      {
        key: 'instagram_engagement_rate',
        label: 'Instagram Engagement Rate',
        prevValue: formatPercent(
          // @ts-ignore
          comparedInfluencer?.instagram?.engagement_rate || 0,
        ),
        currentValue: formatPercent(
          // @ts-ignore
          influencer?.instagram?.engagement_rate || 0,
        ),
        trend: getTrend('instagram.engagement_rate'),
      },
      {
        key: 'tiktok_followers',
        label: 'TikTok Followers',
        prevValue: formatNumber(comparedInfluencer?.tiktok?.followers_nb || 0),
        currentValue: formatNumber(influencer.tiktok?.followers_nb || 0),
        trend: getTrend('tiktok.followers_nb'),
      },
      {
        key: 'tiktok_engagement_rate',
        label: 'TikTok Engagement Rate',
        prevValue: formatPercent(
          // @ts-ignore
          comparedInfluencer?.tiktok?.engagement_rate || 0,
        ),
        // @ts-ignore
        currentValue: formatPercent(influencer.tiktok?.engagement_rate || 0),
        trend: getTrend('tiktok.engagement_rate'),
      },
    ],
    [influencer, comparedInfluencer, getTrend],
  );

  return (
    <ColorCard>
      <CardHeader title="Influencer comparison" />
      <CardContent
        sx={{
          // maxHeight: '70vh',
          overflowY: 'auto',
          pt: 0,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Metric</TableCell>
              <TableCell align="right">
                <Chip
                  size="small"
                  variant="outlined"
                  color="primary"
                  label={getInfluencerName(influencer) || 'Current'}
                />
              </TableCell>
              <TableCell align="right">
                <Chip
                  size="small"
                  variant="outlined"
                  color="secondary"
                  label={
                    comparedInfluencer
                      ? getInfluencerName(comparedInfluencer)
                      : 'Compared'
                  }
                />
              </TableCell>
              <TableCell align="right">Trend</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!comparedInfluencer ? (
              <>
                {tableFields.map((row) => (
                  <TableRow
                    key={row.key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton
                        variant="rectangular"
                        sx={(theme) => ({
                          borderRadius: `${theme.shape.borderRadius}px`,
                        })}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {tableFields.map((row) => (
                  <TableRow
                    key={row.key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>
                    <TableCell align="right">
                      <Typography color="text.secondary" variant="inherit">
                        {row.currentValue}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color="text.secondary" variant="inherit">
                        {row.prevValue}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <TooltipChip
                        title="Trend"
                        color={row.trend === 'up' ? 'success' : 'warning'}
                        sx={{
                          '& span': {
                            display: 'flex',
                            padding: 1,
                          },
                        }}
                        label={
                          row.trend === 'up' ? (
                            <TrendingUpRounded />
                          ) : (
                            <TrendingDownRounded />
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </ColorCard>
  );
};

export default InfluencerComparisonTable;
