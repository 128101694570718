import React, { memo } from 'react';
import {
  alpha,
  Avatar,
  Box,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { AutocompleteSearchItem } from 'api/types/common';
import { getUserInitials } from 'helpers/common';
import { BrandStatusEnum } from 'api/types/Brands';

interface Props {
  liElementProps: React.HTMLAttributes<HTMLLIElement>;
  option: AutocompleteSearchItem;
}

const AutocompleteSearchOption: React.FC<Props> = (props: Props) => {
  const { liElementProps, option } = props;

  const isBlacklisted =
    Array.isArray(option.status) &&
    option.status.includes(BrandStatusEnum.Blacklist);

  return (
    <li {...liElementProps}>
      <ListItemAvatar>
        <Avatar
          src={option.image}
          sx={(theme) => ({
            backgroundColor: isBlacklisted
              ? alpha(theme.palette.error.main, 0.1)
              : alpha(theme.palette.primary.main, 0.1),
            color: isBlacklisted
              ? theme.palette.error.main
              : theme.palette.primary.main,
          })}
        >
          {getUserInitials(option.name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box display="flex" alignItems="center" columnGap={1}>
            <Typography color={isBlacklisted ? 'error' : undefined}>
              {option.name || '-'}
            </Typography>
            {option.is_verified && (
              <VerifiedIcon color="primary" fontSize="small" />
            )}
          </Box>
        }
        secondary={
          isBlacklisted ? (
            <Typography color="error">Influencer is in blacklist</Typography>
          ) : undefined
        }
      />
    </li>
  );
};

export default memo(AutocompleteSearchOption);
