import React, { MouseEventHandler, useCallback, useRef, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import Slider, { Settings } from 'react-slick';
import { DealTypes } from 'api';
import { ArrowDownTinIcon } from 'components/icons';
import { Video, Image } from 'components';
import { isImageUrl } from 'helpers/common';
import imageIcon from 'assets/imageIcon.svg';
import StoriesProgressBar from './StoriesProgressBar';

const PrevButton = ({
  onClick,
  onButtonClick,
  disabled,
  inline,
}: {
  onClick?: MouseEventHandler;
  onButtonClick: () => void;
  disabled: boolean;
  inline?: boolean;
}) => (
  <IconButton
    onClick={(e) => {
      onButtonClick();
      if (onClick) onClick(e);
    }}
    size="small"
    disabled={disabled}
    sx={(theme) => ({
      position: 'absolute',
      left: inline ? '8px' : '-8px',
      top: '50%',
      transform: inline
        ? 'translate(-50%, -50%) rotate(180deg)'
        : 'translate(-100%, -50%) rotate(180deg)',
      zIndex: theme.zIndex.fab,
      backgroundColor: inline ? theme.palette.grey.A200 : undefined,
      boxShadow: inline ? theme.shadows[4] : undefined,
      '&:hover': {
        backgroundColor: inline ? theme.palette.grey.A200 : undefined,
      },
      '&.Mui-disabled': {
        backgroundColor: inline ? theme.palette.grey.A100 : undefined,
      },
    })}
  >
    <ArrowDownTinIcon sx={{ transform: 'rotate(270deg)' }} />
  </IconButton>
);

const NextButton = ({
  onClick,
  onButtonClick,
  disabled,
  inline,
}: {
  onClick?: MouseEventHandler;
  onButtonClick: () => void;
  disabled: boolean;
  inline?: boolean;
}) => (
  <IconButton
    onClick={(e) => {
      onButtonClick();
      if (onClick) onClick(e);
    }}
    size="small"
    disabled={disabled}
    sx={(theme) => ({
      position: 'absolute',
      right: inline ? '8px' : '-8px',
      top: '50%',
      transform: inline ? 'translate(50%, -50%)' : 'translate(100%, -50%)',
      zIndex: theme.zIndex.fab,
      backgroundColor: inline ? theme.palette.grey.A200 : undefined,
      boxShadow: inline ? theme.shadows[4] : undefined,
      '&:hover': {
        backgroundColor: inline ? theme.palette.grey.A200 : undefined,
      },
      '&.Mui-disabled': {
        backgroundColor: inline ? theme.palette.grey.A100 : undefined,
      },
    })}
  >
    <ArrowDownTinIcon sx={{ transform: 'rotate(270deg)' }} />
  </IconButton>
);

interface Props {
  attachments: DealTypes.Post['attachments'];
  inline?: boolean;
  inlineMinHeight?: string;
  maxHeight?: string;
}

const StoriesSlider = (props: Props): JSX.Element | null => {
  const { attachments, inline, inlineMinHeight = '350px', maxHeight } = props;

  const [activeVideo, setActiveVideo] = useState(0);
  const [activeVideoDuration, setActiveVideoDuration] = useState(0);
  const [timestamp, setTimestamp] = useState(0);

  const itemsRef = useRef<HTMLVideoElement[]>([]);

  const handleNextClick = useCallback(() => {
    setActiveVideo((prev) => {
      if (attachments[prev] && attachments[prev + 1]) {
        const isNextImage = isImageUrl(attachments[prev + 1]);
        const isCurrentImage = isImageUrl(attachments[prev]);

        if (!isCurrentImage) {
          if (itemsRef.current[prev]) {
            itemsRef.current[prev]?.pause();
            itemsRef.current[prev].currentTime = 0;
            setTimestamp(0);
          }
        }

        if (isNextImage) {
          return prev + 1;
        }

        const nextItem = itemsRef.current[prev + 1];

        if (nextItem) {
          setActiveVideoDuration(Math.floor(nextItem.duration));
          // eslint-disable-next-line no-console
          nextItem.play().catch((reason) => console.warn(reason));
          return prev + 1;
        }

        return prev;
      }

      return prev;
    });
  }, [attachments]);

  const handlePrevClick = useCallback(() => {
    setActiveVideo((prev) => {
      if (attachments[prev] && attachments[prev - 1]) {
        const isPrevImage = isImageUrl(attachments[prev - 1]);
        const isCurrentImage = isImageUrl(attachments[prev]);

        if (!isCurrentImage) {
          itemsRef.current[prev].pause();
          itemsRef.current[prev].currentTime = 0;
          setTimestamp(0);
        }

        if (isPrevImage) {
          return prev - 1;
        }

        const prevItem = itemsRef.current[prev - 1];

        if (prevItem) {
          setActiveVideoDuration(Math.floor(prevItem.duration));
          // eslint-disable-next-line no-console
          prevItem.play().catch((reason) => console.warn(reason));
          return prev - 1;
        }

        return prev;
      }

      return prev;
    });
  }, [attachments]);

  const handleTimeUpdate = (
    e:
      | React.SyntheticEvent<HTMLVideoElement, Event>
      | React.SyntheticEvent<HTMLDivElement, Event>,
  ) => {
    // @ts-ignore
    const v = Math.floor(e.target?.currentTime);
    if (v !== timestamp) {
      setTimestamp(v);
    }
  };

  const sliderConfig: Settings = {
    dots: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    nextArrow: (
      <NextButton
        onButtonClick={handleNextClick}
        disabled={activeVideo === attachments.length - 1}
        inline={inline}
      />
    ),
    prevArrow: (
      <PrevButton
        onButtonClick={handlePrevClick}
        disabled={activeVideo === 0}
        inline={inline}
      />
    ),
  };

  if (attachments.length === 0) {
    return (
      <Box
        px={inline ? 0 : 6.5}
        sx={{
          position: 'relative',
          height: '100%',
        }}
      >
        <Image
          src={imageIcon}
          sx={{
            width: '100%',
            height: '100%',
            minHeight: inline ? inlineMinHeight : undefined,
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      px={inline ? 0 : 6.5}
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: 0,
          width: inline ? '100%' : `calc(100% - ${theme.spacing(6.5 * 2)})`,
          zIndex: inline ? theme.zIndex.appBar - 1 : theme.zIndex.drawer,
          px: 1.5,
          py: 1,
        })}
      >
        <StoriesProgressBar
          posts={attachments}
          activePost={activeVideo}
          activeVideoDuration={activeVideoDuration}
          timestamp={timestamp}
        />
      </Box>
      <Box
      // sx={{
      //   '& .slick-list': {
      //     borderRadius: inline ? 0 : (t) => `${t.shape.borderRadius}px`,
      //     height: inline ? undefined : height,
      //   },
      // }}
      >
        {/* @ts-ignore */}
        <Slider {...sliderConfig}>
          {attachments.map((url, index) =>
            isImageUrl(url) ? (
              <Image
                src={url}
                ref={(el) => {
                  itemsRef.current[index] = el as HTMLVideoElement;
                }}
                sx={{
                  width: '100%',
                  height: 'auto',
                  minHeight: inline ? inlineMinHeight : undefined,
                  borderRadius: inline ? 0 : (t) => `${t.shape.borderRadius}px`,
                  maxHeight,
                }}
                key={url}
              />
            ) : (
              <Video
                ref={(el) => {
                  itemsRef.current[index] = el as HTMLVideoElement;
                }}
                controls
                src={url}
                sx={{
                  width: '100%',
                  height: 'auto',
                  minHeight: inline ? inlineMinHeight : undefined,
                  borderRadius: inline ? 0 : (t) => `${t.shape.borderRadius}px`,
                  maxHeight,
                  backgroundColor: (theme) => theme.palette.common.black,
                }}
                key={url}
                onTimeUpdate={handleTimeUpdate}
              />
            ),
          )}
        </Slider>
      </Box>
    </Box>
  );
};

export default StoriesSlider;
