import {
  alpha,
  buttonBaseClasses,
  darken,
  inputLabelClasses,
  lighten,
  PaletteMode,
  switchClasses,
  tableSortLabelClasses,
  ThemeOptions,
} from '@mui/material';
import { colors, darkModeColors } from './constants';
import { ArrowDownTinIcon } from '../components/icons';

const components = (mode: PaletteMode): ThemeOptions['components'] => ({
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        scrollBehavior: 'smooth',
      },
      '::-webkit-scrollbar': {
        width: 4,
        height: 4,
        background: mode === 'dark' ? darkModeColors.divider : colors.grey3,
        '&-thumb': {
          background:
            mode === 'dark'
              ? darken(darkModeColors.divider, 0.25)
              : colors.grey2,
          borderRadius: 14,
          '&:hover': {
            background:
              mode === 'dark'
                ? darken(darkModeColors.divider, 0.25)
                : colors.grey2,
          },
        },
      },
    },
  },
  // buttons
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      firstButton: {
        ':hover': {
          borderRightColor: 'transparent',
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        textTransform: 'capitalize',
      },
      outlined: {
        borderColor: mode === 'dark' ? darkModeColors.divider : colors.grey3,
      },
      containedPrimary: {
        '&.Mui-disabled': {
          color: mode === 'dark' ? darkModeColors.textDisabled : colors.grey1,
          backgroundColor:
            mode === 'dark' ? darkModeColors.divider : colors.grey2,
        },
      },
      containedError: {
        color: colors.white,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: 14,
      },
    },
  },
  // inputs
  MuiTextField: {
    defaultProps: {
      size: 'small',
      fullWidth: true,
      variant: 'outlined',
      InputLabelProps: {
        shrink: true,
      },
      InputProps: {
        notched: false,
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: ArrowDownTinIcon,
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        'label + &': {
          marginTop: 32,
        },
      },
      input: {
        padding: '8px 12px',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        [`&.${inputLabelClasses.shrink}`]: {
          transform: 'translate(0, 0) scale(1)',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: mode === 'dark' ? darkModeColors.divider : colors.grey3,
        '& legend': {
          width: 'auto',
        },
      },
      root: {
        borderWidth: 1,
        borderColor: mode === 'dark' ? darkModeColors.divider : colors.grey3,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        // boxShadow: joyShadows.lg,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: mode === 'dark' ? darkModeColors.divider : colors.grey2,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        padding: 8,
        width: 56,
      },
      track: {
        borderRadius: 14,
        opacity: 'unset',
        background:
          mode === 'dark'
            ? lighten(darkModeColors.divider, 0.25)
            : colors.white,
        border: `1px solid ${
          mode === 'dark' ? darkModeColors.divider : colors.grey3
        }`,
      },
      thumb: {
        borderRadius: 13,
        boxShadow: 'unset',
        color:
          mode === 'dark' ? darken(darkModeColors.divider, 0.25) : colors.grey2,
        height: 14,
        width: 14,
      },
      switchBase: {
        padding: 12,
        '&:hover': {
          background: 'unset',
        },
        [`&.${switchClasses.checked}`]: {
          transform: 'translateX(18px)',
          '&:hover': {
            background: 'unset',
          },
          [`& .${switchClasses.thumb}`]: {
            color: colors.white,
          },
          [`&+.${switchClasses.track}`]: {
            opacity: 'unset',
            border: `1px solid ${
              mode === 'dark' ? darkModeColors.primary : colors.accent
            }`,
            backgroundColor:
              mode === 'dark' ? darkModeColors.primary : colors.accent,
          },
        },
        [`&.${switchClasses.disabled}`]: {
          [`&+.${switchClasses.track}`]: {
            opacity: '0.5',
          },
        },
      },
    },
  },
  // table
  MuiTableCell: {
    styleOverrides: {
      stickyHeader: {
        backgroundColor:
          mode === 'dark' ? darkModeColors.background : colors.white,
      },
      root: {
        borderColor: mode === 'dark' ? darkModeColors.divider : colors.grey3,
        padding: '10px',
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        [`&.${buttonBaseClasses.root}.Mui-active`]: {
          color: mode === 'dark' ? darkModeColors.primary : colors.accent,
          [`& .${tableSortLabelClasses.icon}`]: {
            color: mode === 'dark' ? darkModeColors.primary : colors.accent,
          },
        },
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        borderRadius: 10,
        '&.Mui-disabled': {
          color: colors.dark2,
          backgroundColor: colors.grey2,
        },
      },
      previousNext: {
        color: mode === 'dark' ? colors.white : darkModeColors.primary,
        backgroundColor:
          mode === 'dark'
            ? alpha(darkModeColors.primary, 0.75)
            : lighten(colors.accent, 0.9),
      },
      firstLast: {
        color: mode === 'dark' ? colors.white : darkModeColors.primary,
        backgroundColor:
          mode === 'dark'
            ? alpha(darkModeColors.primary, 0.75)
            : lighten(colors.accent, 0.9),
      },
    },
  },
  // other
  MuiCard: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        '&:last-child': {
          paddingBottom: '16px',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        fontWeight: 400,
      },
      outlined: {
        border: 'unset',
        '&.MuiChip-colorPrimary': {
          color:
            mode === 'dark'
              ? darken(darkModeColors.primary, 0.8)
              : colors.accent,
          backgroundColor:
            mode === 'dark'
              ? lighten(darken(darkModeColors.primary, 0.2), 0.15)
              : alpha(darken(colors.accent, 0.2), 0.15),
        },
        '&.MuiChip-colorSecondary': {
          color:
            mode === 'dark'
              ? darken(darkModeColors.secondary, 0.8)
              : colors.purple,
          backgroundColor:
            mode === 'dark'
              ? lighten(darken(darkModeColors.secondary, 0.2), 0.15)
              : alpha(darken(colors.purple, 0.2), 0.15),
        },
        '&.MuiChip-colorDefault': {
          color: mode === 'dark' ? darken(colors.grey1, 0.8) : colors.grey1,
          backgroundColor:
            mode === 'dark'
              ? lighten(darken(colors.grey1, 0.2), 0.15)
              : alpha(darken(colors.grey1, 0.2), 0.15),
        },
        '&.MuiChip-colorSuccess': {
          color:
            mode === 'dark' ? darken(colors.darkGreen, 0.8) : colors.darkGreen,
          backgroundColor:
            mode === 'dark'
              ? lighten(darken(colors.green, 0.2), 0.15)
              : alpha(colors.green, 0.15),
        },
        '&.MuiChip-colorInfo': {
          color: mode === 'dark' ? darken(colors.blue, 0.8) : colors.darkBlue,
          backgroundColor:
            mode === 'dark'
              ? lighten(darken(colors.blue, 0.2), 0.15)
              : alpha(colors.blue, 0.15),
        },
        '&.MuiChip-colorWarning': {
          color:
            mode === 'dark' ? darken(colors.orange, 0.8) : colors.darkOrange,
          backgroundColor:
            mode === 'dark'
              ? lighten(darken(colors.orange, 0.2), 0.15)
              : alpha(colors.orange, 0.15),
        },
        '&.MuiChip-colorError': {
          color: mode === 'dark' ? darken(colors.red, 0.8) : colors.darkRed,
          backgroundColor:
            mode === 'dark'
              ? lighten(darken(colors.red, 0.2), 0.15)
              : alpha(colors.red, 0.15),
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor:
          mode === 'dark' ? darkModeColors.primary : colors.accent,
      },
    },
  },
  MuiAvatarGroup: {
    styleOverrides: {
      root: {
        '& .MuiAvatar-root': {
          borderColor: mode === 'dark' ? darkModeColors.divider : colors.grey3,
        },
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      enterDelay: 500,
      enterNextDelay: 500,
    },
    styleOverrides: {
      tooltip: {
        fontSize: 14,
        fontWeight: 400,
      },
    },
  },
  MuiDialog: {
    defaultProps: {
      PaperProps: {
        elevation: 2,
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
      },
    },
  },
});

export default components;
