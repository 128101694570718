import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Control,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { DiscountOutlined, RestartAltOutlined } from '@mui/icons-material';
import { campaigns, DealTypes } from 'api';
import DEALS from 'constants/deals';
import { CountriesEnum } from 'api/types/Countries';
import DISCOUNTS from 'constants/discounts';
import {
  getProductCategories,
  getProductOptions,
  getProducts,
} from 'helpers/products';
import { AutocompleteSearchItem } from 'api/types/common';
import { FormCard, FormInput, Loader } from 'components';
import CouponCodeInput from './CouponCodeInput';

interface Props {
  id: string | undefined;
  control: Control<DealTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<DealTypes.ItemCreateParams>;
  setValue: UseFormSetValue<DealTypes.ItemCreateParams>;
  setError: UseFormSetError<DealTypes.ItemCreateParams>;
}

const DiscountBlock = (props: Props): JSX.Element | null => {
  const { control, watch, setValue, id, setError } = props;

  const [initialCampaignsLoaded, setInitialCampaignsLoaded] = useState(false);

  const brand = watch('brand');
  const countryTeam = watch('country_team');
  const status = watch('status');
  const channel = watch('channel');
  const discountType = watch('discount_type');
  const currency = watch('currency');
  const freeProductCategories = watch('free_gift_product_categories');
  const freeProducts = watch('free_gift_products');
  const campaignIds = watch('campaign_ids');

  const isLoadCampaignsEnabled = useMemo(
    () =>
      !!id && !initialCampaignsLoaded && campaignIds && campaignIds.length > 0,
    [campaignIds, id, initialCampaignsLoaded],
  );

  const { data, isLoading } = useQuery(
    [campaigns.endpoints.getList, campaignIds],
    () =>
      campaigns
        .getList({ filters: { _id: campaignIds } })
        .then((res) => res.data.map((r) => ({ _id: r._id, name: r.name }))),
    {
      enabled: isLoadCampaignsEnabled,
      onError: () => {
        setError('campaign_ids', {
          message: 'Failed to load campaigns data',
        });
      },
      onSuccess: () => setInitialCampaignsLoaded(true),
    },
  );

  const isPostUrlActive = useMemo(
    () =>
      (
        [
          DealTypes.ChannelInfluencerEnum.InstagramPost,
          DealTypes.ChannelInfluencerEnum.InstagramReels,
          DealTypes.ChannelInfluencerEnum.InstagramTVLive,
        ] as DealTypes.ChannelEnum[]
      ).includes(channel),
    [channel],
  );

  const isActiveDiscountValue = useMemo(
    () =>
      discountType &&
      (discountType.indexOf('Percentage discount') >= 0 ||
        discountType.indexOf('Value discount') >= 0),
    [discountType],
  );

  const isActiveDiscountGift = useMemo(
    () =>
      discountType &&
      (discountType.indexOf('gift') >= 0 || discountType.indexOf('gift') >= 0),
    [discountType],
  );

  const handleCampaignsChange = useCallback(
    (
      value: AutocompleteSearchItem | AutocompleteSearchItem[] | null,
      onChange: (value: string | string[] | null) => void,
    ) => {
      if (value && Array.isArray(value)) {
        setValue(
          'campaigns',
          value.map((v) => ({ name: v.name, _id: v._id })),
        );
        onChange(value.map((v) => v._id.$oid));
      }
    },
    [setValue],
  );

  const handleDiscountTypeChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      setValue('discount_value', undefined);
      setValue('free_gift_product_categories', []);
      setValue('free_gift_products', []);

      onChange(event.target.value);
    },
    [setValue],
  );

  const handleFreeProductCategoryChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      if (freeProducts && freeProducts.length > 0) {
        const availableProducts = getProducts(
          brand,
          (event.target.value || []) as unknown as never[],
        );

        const selectedProducts = freeProducts.filter((p) =>
          availableProducts.includes(p),
        );

        setValue('products', selectedProducts);
      }

      onChange(event.target.value);
    },
    [brand, freeProducts, setValue],
  );

  const handleResetBlock = useCallback(() => {
    setValue('campaigns', []);
    setValue('campaign_ids', []);
    setValue('coupon_code', null);
    setValue('coupon_description', null);
    setValue('discount_type', null);
    setValue('discount_description', null);
    setValue('discount_value', null);
    setValue('free_gift_products', []);
    setValue('free_gift_product_categories', []);
  }, [setValue]);

  useEffect(() => {
    if (brand && countryTeam && countryTeam !== CountriesEnum.IN) {
      setValue('stories_tag', DEALS.STORIES_TAG_ENUM[brand][countryTeam]);
    }
  }, [brand, countryTeam, setValue]);

  return (
    <FormCard
      title="Discount details"
      avatar={<DiscountOutlined />}
      CardHeaderProps={{
        action: (
          <Tooltip title="Reset discount details">
            <IconButton onClick={handleResetBlock}>
              <RestartAltOutlined
                sx={(theme) => ({ color: theme.palette.text.primary })}
              />
            </IconButton>
          </Tooltip>
        ),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isLoadCampaignsEnabled && isLoading ? (
            <Loader variant="centered" height="40px" />
          ) : (
            <FormInput
              control={control}
              name="campaign_ids"
              inputType="autocompleteSearch"
              label="Campaigns"
              multiple
              initialValue={data}
              queryFn={(q) => campaigns.autocompleteSearch({ name: q })}
              queryKey={campaigns.endpoints.autocompleteSearch}
              onChange={handleCampaignsChange}
            />
          )}
        </Grid>
        {channel === DealTypes.ChannelInfluencerEnum.InstagramStories && (
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="stories_tag"
              inputType="input"
              label="Tagged username"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CouponCodeInput
            control={control}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            control={control}
            name="coupon_description"
            inputType="textarea"
            label="Coupon description"
            minRows={2}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="post.clicks_nb"
            inputType="number"
            tooltip="Can be edited only if deal status is Posted or Finished"
            label="Number of clicks"
            disabled={
              status !== DealTypes.StatusEnum.Posted &&
              status !== DealTypes.StatusEnum.Finished
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="post.views_nb"
            inputType="number"
            tooltip="Can be edited only if deal status is Posted or Finished"
            label="Number of views"
            disabled={
              status !== DealTypes.StatusEnum.Posted &&
              status !== DealTypes.StatusEnum.Finished
            }
          />
        </Grid>

        {isPostUrlActive && (
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="post.source_url"
              inputType="input"
              label="Post URL"
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <FormInput
            control={control}
            name="discount_type"
            inputType="select"
            label="Discount type"
            tooltip={
              'Changing the discount type resets the "Discount value" and selected products and products categories'
            }
            options={DISCOUNTS.DETAILS_OPTIONS}
            onChange={handleDiscountTypeChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            control={control}
            name="discount_description"
            inputType="textarea"
            minRows={2}
            label="Percentage description"
          />
        </Grid>
        {isActiveDiscountValue && (
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="discount_value"
              inputType="number"
              label="Discount value"
              InputProps={{
                endAdornment:
                  discountType &&
                  discountType.indexOf('Percentage discount') >= 0
                    ? '%'
                    : currency || 'EUR',
              }}
              rules={{
                onBlur: (e) =>
                  setValue('discount_value', +(+e.target.value).toFixed(2)),
              }}
            />
          </Grid>
        )}
        {isActiveDiscountGift && (
          <>
            <Grid item xs={12}>
              <FormInput
                control={control}
                name="free_gift_product_categories"
                inputType="select"
                label="Gift product categories"
                tooltip="After product category change selected products list will be changed. If products is not available in selected category they will be removed"
                options={getProductCategories(brand)}
                onChange={handleFreeProductCategoryChange}
                SelectProps={{
                  multiple: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                control={control}
                name="free_gift_products"
                inputType="select"
                label="Gift products"
                options={getProductOptions(
                  brand,
                  freeProductCategories as never[],
                )}
                disabled={freeProductCategories?.length === 0}
                SelectProps={{ multiple: true }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </FormCard>
  );
};

export default DiscountBlock;
