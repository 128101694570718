import React, { ChangeEvent, useCallback } from 'react';
import { Control, UseFormWatch, UseFormSetValue } from 'react-hook-form';
import { Grid } from '@mui/material';
import { PercentOutlined, PointOfSaleOutlined } from '@mui/icons-material';
import { DealTypes } from 'api';
import DEALS from 'constants/deals';
import { getBudgetPotential, getRevenue, getRoi } from 'helpers/calculations';
import { FormInput, FormCard } from 'components';

interface Props {
  control: Control<DealTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<DealTypes.ItemCreateParams>;
  setValue: UseFormSetValue<DealTypes.ItemCreateParams>;
}

const BudgetBlock = (props: Props): JSX.Element | null => {
  const { control, watch, setValue } = props;

  const budgetType = watch('budget_type');
  const budgetPotential = watch('budget_potential_lc');
  const roiPotential = watch('roi_potential');
  const revenuePotential = watch('revenue_potential_lc');
  const budgetFixed = watch('budget_fixed');
  const commission = watch('commission');
  const currency = watch('currency');

  const handleBudgetTypeChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      setValue('budget_potential_lc', 0);
      setValue('roi_potential', undefined);
      setValue('revenue_potential_lc', undefined);
      setValue('commission', undefined);
      setValue('budget_fixed', undefined);

      onChange(event.target.value);
    },
    [setValue],
  );

  const handleBudgetPotentialChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | number | undefined) => void,
    ) => {
      const value = event.target.value ? +event.target.value : '';

      if (budgetType === DealTypes.BudgetTypeEnum.fixedBudget) {
        if (value && value > 0) {
          if (roiPotential) {
            const revenue = getRevenue({
              budget: value,
              roi: roiPotential,
            });
            setValue('revenue_potential_lc', revenue);
          } else if (revenuePotential) {
            const roi = getRoi({
              budget: value,
              revenue: revenuePotential,
            });
            setValue('roi_potential', roi);
          }
        }
      }

      onChange(value);
    },
    [budgetType, revenuePotential, roiPotential, setValue],
  );

  const handlePotentialRoiChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | number | undefined) => void,
    ) => {
      const value = event.target.value ? +event.target.value : '';

      const revenue = getRevenue({
        budget: budgetPotential,
        roi: value || 0,
      });
      setValue('revenue_potential_lc', revenue);

      if (value && budgetType !== DealTypes.BudgetTypeEnum.fixedBudget) {
        const budget = getBudgetPotential({
          budgetType,
          budgetFixed,
          revenuePotential: revenue,
          commission,
        });

        setValue('budget_potential_lc', budget);
      }

      onChange(value);
    },
    [budgetFixed, budgetPotential, budgetType, commission, setValue],
  );

  const handlePotentialRevenueChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | number | undefined) => void,
    ) => {
      const value = event.target.value ? +event.target.value : '';

      if (value) {
        if (budgetType === DealTypes.BudgetTypeEnum.fixedBudget) {
          const roi = getRoi({
            budget: budgetPotential,
            revenue: value,
          });
          setValue('roi_potential', roi);
        } else {
          const budget = getBudgetPotential({
            budgetType,
            budgetFixed,
            revenuePotential: value,
            commission,
          });

          const roi = getRoi({
            budget,
            revenue: value,
          });
          setValue('roi_potential', roi);
          setValue('budget_potential_lc', budget);
        }
      }

      onChange(value);
    },
    [budgetFixed, budgetPotential, budgetType, commission, setValue],
  );

  const handleCommissionChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | number | undefined) => void,
    ) => {
      const value = event.target.value ? +event.target.value : '';

      if (budgetType !== DealTypes.BudgetTypeEnum.fixedBudget) {
        const budget = getBudgetPotential({
          budgetType,
          budgetFixed,
          revenuePotential,
          commission: value || 0,
        });
        setValue('budget_potential_lc', budget);

        if (!!revenuePotential && budget > 0) {
          const roi = getRoi({
            budget,
            revenue: revenuePotential,
          });
          setValue('roi_potential', roi);
        }
      }

      onChange(value);
    },
    [budgetFixed, budgetType, revenuePotential, setValue],
  );

  const handleBudgetFixedChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | number | undefined) => void,
    ) => {
      const value = event.target.value ? +event.target.value : '';

      const budget = getBudgetPotential({
        budgetType,
        budgetFixed: value || 0,
        revenuePotential,
        commission,
      });
      const roi = getRoi({
        budget,
        revenue: revenuePotential,
      });
      setValue('budget_potential_lc', budget);
      setValue('roi_potential', roi);

      onChange(value);
    },
    [budgetType, commission, revenuePotential, setValue],
  );

  return (
    <FormCard title="Budget & Revenue details" avatar={<PointOfSaleOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="budget_type"
            inputType="select"
            label="Budget type"
            tooltip="Other budget fields will reset after budget type change"
            options={DEALS.BUDGET_TYPE_OPTIONS}
            onChange={handleBudgetTypeChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="budget_potential_lc"
            inputType="number"
            label="Budget"
            tooltip={
              'If budget type is "Fixed budget" changing this may trigger recalculations of Revenue and ROI based on other field values'
            }
            disabled={budgetType !== DealTypes.BudgetTypeEnum.fixedBudget}
            onChange={handleBudgetPotentialChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="roi_potential"
            inputType="number"
            label="ROI Potential"
            tooltip="Changing the ROI Potential will trigger recalculations of Revenue Potential and may affect Budget Potential based on your Budget Type"
            disabled={
              budgetType !== DealTypes.BudgetTypeEnum.fixedBudget ||
              budgetPotential === 0
            }
            onChange={handlePotentialRoiChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="revenue_potential_lc"
            inputType="number"
            label="Revenue Potential"
            tooltip="Changing the Revenue Potential value prompts recalculations of ROI and Budget Potential, depending on your Budget Type"
            onChange={handlePotentialRevenueChange}
            InputProps={{
              endAdornment: currency,
            }}
          />
        </Grid>
        {budgetType !== DealTypes.BudgetTypeEnum.fixedBudget && (
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="commission"
              inputType="number"
              label="Commission rate"
              tooltip="Changing the Commission Rate may affect the calculation of the Budget Potential"
              onChange={handleCommissionChange}
              InputProps={{
                endAdornment: <PercentOutlined fontSize="small" />,
              }}
            />
          </Grid>
        )}
        {budgetType === DealTypes.BudgetTypeEnum.fixedAndCommission && (
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="budget_fixed"
              inputType="number"
              label="Fixed budget"
              tooltip="Changing the Fixed Budget value influences the Budget Potential and can impact Revenue Potential and ROI calculations"
              onChange={handleBudgetFixedChange}
            />
          </Grid>
        )}
      </Grid>
    </FormCard>
  );
};

export default BudgetBlock;
