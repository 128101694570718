import React, { useCallback, useMemo, useState } from 'react';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  debounce,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import { AutocompleteSearchItem } from 'api/types/common';
import { influencers } from 'api';
import AutocompleteOption from './AutocompleteOption';

type Props = {
  query: string[];
  setQuery: (value: string[]) => void;
};

const AutocompleteSearch = (props: Props) => {
  const { setQuery, query } = props;

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { data, isLoading } = useQuery(
    [influencers.endpoints.autocompleteSearch, inputValue],
    () => influencers.autocompleteSearch({ global_search_string: inputValue }),
    { enabled: open, initialData: [] },
  );

  const initialValue = useMemo(() => {
    const options = uniqBy(data, 'name') || [];
    if (query) {
      return options.filter((o) => query.includes(o.name));
    }

    return [];
  }, [data, query]);

  const [value, setValue] = useState<AutocompleteSearchItem[]>(initialValue);

  const options = useMemo(() => {
    if (inputValue) {
      return uniqBy(data, 'name') || [];
    }
    return uniqBy([...value, ...data], 'name') || [];
  }, [data, inputValue, value]);

  const handleValueChange = useCallback(
    (_: unknown, newValue: AutocompleteSearchItem[] | undefined) => {
      setValue(newValue || []);

      const names = (newValue || []).map((v) => v.name);
      setQuery(names);
    },
    [setQuery],
  );

  const handleInputChange = debounce(
    (
      event: unknown,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'reset') {
        return;
      }
      setInputValue(newInputValue);
    },
    500,
  );

  const handleSearch = useCallback(() => {
    const names = value.map((v) => v.name);

    setQuery(names);
    setOpen(false);
  }, [setQuery, value]);

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();

        handleSearch();
      }
    },
    [handleSearch],
  );

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      multiple
      value={value}
      clearOnEscape
      popupIcon={false}
      disableClearable
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      onChange={handleValueChange}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(
        option: AutocompleteSearchItem,
        value: AutocompleteSearchItem,
      ) => option._id.$oid === value._id.$oid}
      getOptionLabel={(option: AutocompleteSearchItem) => option.name}
      options={options}
      loading={isLoading}
      renderOption={(props1, option) => (
        <AutocompleteOption option={option} {...props1} />
      )}
      ChipProps={{
        color: 'primary',
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          placeholder="Search for influencer to compare"
          onKeyUp={isLoading ? () => false : handleKeyUp}
          InputProps={{
            ...params.InputProps,
            sx: (theme) => ({
              backgroundColor: theme.palette.primary.light,
              '& fieldset': {
                borderColor: 'transparent',
              },
            }),
          }}
        />
      )}
    />
  );
};
export default AutocompleteSearch;
