import React, { useCallback, useMemo } from 'react';
import { CampaignTypes } from 'api';
import { colors } from 'theme/constants';
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from 'helpers/formatters';
import CardTable, { ComparisonTableItems } from './CardTable';

interface Props {
  campaign: CampaignTypes.ItemData;
  comparedCampaign?: CampaignTypes.ItemData;
}

const ComparisonTable = (props: Props): JSX.Element | null => {
  const { campaign, comparedCampaign } = props;

  const getTrend = useCallback(
    (key: keyof CampaignTypes.ItemData) =>
      (campaign[key] || 0) > (comparedCampaign ? comparedCampaign[key] : 0)
        ? 'up'
        : 'down',
    [campaign, comparedCampaign],
  );

  const getTrendRatio = useCallback(
    (key: keyof CampaignTypes.ItemData) => {
      const current = campaign[key] || 0;
      const previous = comparedCampaign ? comparedCampaign[key] || 0 : 0;
      return previous === 0 ? 0 : ((current - previous) / previous) * 100;
    },
    [campaign, comparedCampaign],
  );

  const tableFields: ComparisonTableItems<CampaignTypes.ItemData> = useMemo(
    () => [
      {
        key: 'roi_target',
        label: 'ROI Target',
        prevValue: formatNumber(comparedCampaign?.roi_target || 0),
        currentValue: formatNumber(campaign.roi_target || 0),
        trend: getTrend('roi_target'),
        trendRatio: formatPercent(getTrendRatio('roi_target')),
      },
      {
        key: 'budget_target',
        label: 'Budget Target',
        prevValue: formatCurrency(comparedCampaign?.budget_target || 0),
        currentValue: formatCurrency(campaign.budget_target || 0),
        trend: getTrend('budget_target'),
        trendRatio: formatPercent(getTrendRatio('budget_target')),
      },
      {
        key: 'budget_actual',
        label: 'Budget Actual',
        prevValue: formatCurrency(comparedCampaign?.budget_actual || 0),
        currentValue: formatCurrency(campaign.budget_actual || 0),
        trend: getTrend('budget_actual'),
        trendRatio: formatPercent(getTrendRatio('budget_actual')),
      },
      {
        key: 'budget_traffic_light',
        label: 'Budget Traffic Light',
        prevValue: formatPercent(comparedCampaign?.budget_traffic_light || 0),
        currentValue: formatPercent(campaign.budget_traffic_light || 0),
        trend: getTrend('budget_traffic_light'),
        trendRatio: formatPercent(getTrendRatio('budget_traffic_light')),
      },
      {
        key: 'budget_running_forecast',
        label: 'Budget Running Forecast',
        prevValue: formatCurrency(
          comparedCampaign?.budget_running_forecast || 0,
        ),
        currentValue: formatCurrency(campaign.budget_running_forecast || 0),
        trend: getTrend('budget_running_forecast'),
        trendRatio: formatPercent(getTrendRatio('budget_running_forecast')),
      },
      {
        key: 'revenue_target',
        label: 'Revenue Target',
        prevValue: formatCurrency(comparedCampaign?.revenue_target || 0),
        currentValue: formatCurrency(campaign.revenue_target || 0),
        trend: getTrend('revenue_target'),
        trendRatio: formatPercent(getTrendRatio('revenue_target')),
      },
      {
        key: 'revenue_actual',
        label: 'Revenue Actual',
        prevValue: formatCurrency(comparedCampaign?.revenue_actual || 0),
        currentValue: formatCurrency(campaign.revenue_actual || 0),
        trend: getTrend('revenue_actual'),
        trendRatio: formatPercent(getTrendRatio('revenue_actual')),
      },
      {
        key: 'revenue_traffic_light',
        label: 'Revenue Traffic Light',
        prevValue: formatPercent(comparedCampaign?.revenue_traffic_light || 0),
        currentValue: formatPercent(campaign.revenue_traffic_light || 0),
        trend: getTrend('revenue_traffic_light'),
        trendRatio: formatPercent(getTrendRatio('revenue_traffic_light')),
      },
      {
        key: 'revenue_running_forecast',
        label: 'Revenue Running Forecast',
        prevValue: formatCurrency(
          comparedCampaign?.revenue_running_forecast || 0,
        ),
        currentValue: formatCurrency(campaign.revenue_running_forecast || 0),
        trend: getTrend('revenue_running_forecast'),
        trendRatio: formatPercent(getTrendRatio('revenue_running_forecast')),
      },
      {
        key: 'aov_target',
        label: 'AOV Target',
        prevValue: formatCurrency(comparedCampaign?.aov_target || 0),
        currentValue: formatCurrency(campaign.aov_target || 0),
        trend: getTrend('aov_target'),
        trendRatio: formatPercent(getTrendRatio('aov_target')),
      },
      {
        key: 'aov_actual',
        label: 'AOV Actual',
        prevValue: formatCurrency(comparedCampaign?.aov_actual || 0),
        currentValue: formatCurrency(campaign.aov_actual || 0),
        trend: getTrend('aov_actual'),
        trendRatio: formatPercent(getTrendRatio('aov_actual')),
      },
      {
        key: 'new_customers_actual',
        label: 'New Customers Actual',
        prevValue: formatNumber(comparedCampaign?.new_customers_actual || 0),
        currentValue: formatNumber(campaign.new_customers_actual || 0),
        trend: getTrend('new_customers_actual'),
        trendRatio: formatPercent(getTrendRatio('new_customers_actual')),
      },
    ],
    [campaign, comparedCampaign, getTrend, getTrendRatio],
  );

  return (
    <CardTable
      color={colors.orange}
      title="Campaigns metrics comparison"
      data={tableFields}
      comparedTitle={comparedCampaign?.name}
      currentTitle={campaign?.name}
      loading={!comparedCampaign}
    />
  );
};

export default ComparisonTable;
