import React from 'react';
import {
  Control,
  UseFormTrigger,
  UseFormWatch,
  UseControllerProps,
} from 'react-hook-form';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { InfluencerTypes } from 'api';
import { FormCard, FormInput } from 'components';
import {
  InstagramIcon,
  PinterestIcon,
  SnapchatIcon,
  TikTokIcon,
  YoutubeIcon,
} from 'components/icons';

interface Props {
  control: Control<InfluencerTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<InfluencerTypes.ItemCreateParams>;
  trigger: UseFormTrigger<InfluencerTypes.ItemCreateParams>;
}

const SocialNetworksBlock = (props: Props): JSX.Element | null => {
  const { control, watch, trigger } = props;

  const instagramUsername = watch('instagram.username');
  const tiktokUsername = watch('tiktok.username');
  const youtubeUsername = watch('youtube.username');
  const pinterestUsername = watch('pinterest.username');
  const snapchatUsername = watch('snapchat.username');

  const isUsernameEmpty = !(
    instagramUsername ||
    tiktokUsername ||
    youtubeUsername ||
    pinterestUsername ||
    snapchatUsername
  );

  const rules: UseControllerProps<InfluencerTypes.ItemCreateParams>['rules'] = {
    required: isUsernameEmpty,
    onBlur: () => {
      trigger('instagram.username');
      trigger('tiktok.username');
      trigger('youtube.username');
      trigger('pinterest.username');
      trigger('snapchat.username');
    },
  };

  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="h5" paragraph>
        Social networks
      </Typography>
      <Stack rowGap={2}>
        <FormCard title="Instagram" avatar={<InstagramIcon />}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormInput
                inputType="input"
                control={control}
                name="instagram.username"
                label="Username"
                rules={rules}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                inputType="number"
                control={control}
                name="instagram_post.avg_views_nb"
                label="Average post views"
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                inputType="number"
                control={control}
                name="instagram_story.avg_views_nb"
                label="Average story views"
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                inputType="number"
                control={control}
                name="instagram_story.avg_link_clicks_nb"
                label="Average link clicks"
              />
            </Grid>
          </Grid>
        </FormCard>
        <FormCard title="Tiktok" avatar={<TikTokIcon />}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormInput
                inputType="input"
                control={control}
                name="tiktok.username"
                label="Username"
                rules={rules}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                inputType="number"
                control={control}
                name="tiktok_post.avg_views_nb"
                label="Average post views"
              />
            </Grid>
          </Grid>
        </FormCard>
        <FormCard title="Youtube" avatar={<YoutubeIcon />}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormInput
                inputType="input"
                control={control}
                name="youtube.username"
                label="Username"
                rules={rules}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                inputType="input"
                control={control}
                name="youtube.page_url"
                label="Page URL"
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                inputType="number"
                control={control}
                name="youtube_post.avg_views_nb"
                label="Average post views"
              />
            </Grid>
          </Grid>
        </FormCard>
        <FormCard title="Pinterest" avatar={<PinterestIcon />}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormInput
                inputType="input"
                control={control}
                name="pinterest.username"
                label="Username"
                rules={rules}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                inputType="number"
                control={control}
                name="pinterest_post.avg_views_nb"
                label="Average post views"
              />
            </Grid>
          </Grid>
        </FormCard>
        <FormCard title="Snapchat" avatar={<SnapchatIcon />}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormInput
                inputType="input"
                control={control}
                name="snapchat.username"
                label="Username"
                rules={{
                  required: isUsernameEmpty,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                inputType="number"
                control={control}
                name="snapchat_post.avg_views_nb"
                label="Average post views"
              />
            </Grid>
          </Grid>
        </FormCard>
      </Stack>
    </Card>
  );
};

export default SocialNetworksBlock;
