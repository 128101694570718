import React, { useEffect, useMemo, useState } from 'react';
import { deals, DealTypes, influencers } from 'api';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ErrorBoundary, Loader } from 'components';
import { useStoreContext } from 'store';
import { AutocompleteSearchItem } from 'api/types/common';
import { getCurrentDate } from 'helpers/dayJsFormats';
import { appLinks } from 'routes/links';
import Form from './Form';
import { dataToInitialModel, initialModel } from './fields';

type Params = 'id';

interface Props {
  itemId?: string;
}

const Index = (props: Props): JSX.Element | null => {
  const { itemId } = props;
  const { id: paramsId = '' } = useParams<Params>();
  const {
    state: { user },
  } = useStoreContext();
  const { enqueueSnackbar } = useSnackbar();

  const id = useMemo(() => paramsId || itemId || '', [itemId, paramsId]);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const influencerId = useMemo(() => {
    const parsed = Object.fromEntries(searchParams);

    return 'influencerId' in parsed ? parsed.influencerId : undefined;
  }, [searchParams]);

  const cloneDealId = useMemo(() => {
    const parsed = Object.fromEntries(searchParams);

    return 'cloneDealId' in parsed ? parsed.cloneDealId : undefined;
  }, [searchParams]);

  const { data: influencerData } = useQuery(
    [influencers.endpoints.getById(influencerId || ''), influencerId],
    () => influencers.getById(influencerId || ''),
    {
      enabled: !!influencerId,
    },
  );

  const [initialData, setInitialData] = useState<
    | {
        initialFormData: DealTypes.ItemCreateParams;
        initialInfluencer: AutocompleteSearchItem | undefined;
      }
    | undefined
  >(undefined);

  const { data, error } = useQuery(
    [deals.endpoints.getById(cloneDealId || id)],
    () => deals.getById(cloneDealId || id),
    {
      enabled: !!(cloneDealId || id),
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (!id && !influencerId && !cloneDealId) {
      setInitialData({
        initialFormData: {
          ...initialModel,
          lead: user?.full_name,
          start_date: getCurrentDate(),
        } as DealTypes.ItemCreateParams,
        initialInfluencer: undefined,
      });
    }

    if (!id && influencerId && influencerData) {
      setInitialData({
        initialFormData: {
          ...initialModel,
          lead: user?.full_name,
          start_date: getCurrentDate(),
          influencer_id: influencerData._id.$oid,
        } as DealTypes.ItemCreateParams,
        initialInfluencer: {
          _id: influencerData._id,
          name: influencerData.profile_name || 'unknown',
        },
      });
    }

    if (!id && cloneDealId && data) {
      const initialFormData = dataToInitialModel(data);
      const initialInfluencer: AutocompleteSearchItem = {
        _id: data.influencer_id,
        name: data.influencer_profile_name || 'unknown',
      };

      setInitialData({
        initialFormData,
        initialInfluencer,
      });
    }

    if (id && data) {
      if (data.is_deleted) {
        navigate(appLinks.deals.details.as(id), { replace: true });
      }

      const initialFormData = dataToInitialModel(data);
      const initialInfluencer: AutocompleteSearchItem = {
        _id: data.influencer_id,
        name: data.influencer_profile_name || 'unknown',
      };

      setInitialData({
        initialFormData,
        initialInfluencer,
      });
    }
  }, [
    cloneDealId,
    data,
    id,
    influencerData,
    influencerId,
    user?.full_name,
    navigate,
    enqueueSnackbar,
  ]);

  if (error) {
    return <ErrorBoundary variant="error" />;
  }

  return !initialData ? (
    <Loader height="80vh" variant="centered" />
  ) : (
    <Form
      drawer={!!itemId}
      drawerTitle={`Edit ${
        data?.influencer_profile_name
          ? `${data.influencer_profile_name} deal`
          : 'deal'
      }`}
      id={id}
      initialData={initialData.initialFormData}
      initialInfluencer={initialData.initialInfluencer}
    />
  );
};

export default Index;
