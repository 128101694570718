import React, { useEffect, useMemo, useState } from 'react';
import { CardContent, CardHeader, Link, Typography } from '@mui/material';
import { appLinks } from 'routes/links';
import { useQuery } from '@tanstack/react-query';
import { influencers, InfluencerTypes } from 'api';
import { colors } from 'theme/constants';
import { ColorCard } from 'components';
import { getInfluencerName } from 'helpers/common';
import Search from './Search';

interface Props {
  influencer: InfluencerTypes.ItemData;
  compared?: InfluencerTypes.ItemData;
  setCompared: React.Dispatch<
    React.SetStateAction<InfluencerTypes.ItemData | undefined>
  >;
}

const CampaignComparisonCard = (props: Props): JSX.Element | null => {
  const { influencer, setCompared, compared } = props;

  const [query, setQuery] = useState<string[]>([]);

  const initQueryParams: InfluencerTypes.GetListParams = useMemo(
    () => ({
      sort_by: 'start_date',
      sort_desc: true,
      limit: 2,
      page: 1,
      filters: {
        is_verified: influencer.is_verified,
        target_countries: influencer.target_countries,
        platform_types: influencer.platform_types,
        vertical_primary: influencer.vertical_primary
          ? [influencer.vertical_primary]
          : undefined,
        'personal_info.age_group': influencer.personal_info?.age_group
          ? [influencer.personal_info?.age_group]
          : undefined,
      },
    }),
    [influencer],
  );
  const queryParams: InfluencerTypes.GetListParams = useMemo(
    () => ({
      sort_by: 'start_date',
      sort_desc: true,
      limit: 2,
      page: 1,
      filters: {
        global_search_string: query.length > 0 ? query : undefined,
      },
    }),
    [query],
  );

  const { data } = useQuery(
    [influencers.endpoints.getList, queryParams, initQueryParams],
    () =>
      influencers
        .getList(query.length > 0 ? queryParams : initQueryParams)
        .then((res) =>
          res.data.filter((r) => r._id.$oid !== influencer._id.$oid),
        ),
  );

  const prev = useMemo(() => (data ? data[0] : undefined), [data]);

  useEffect(() => {
    if (prev) {
      setCompared(prev);
    }
  }, [prev, setCompared]);

  return (
    <ColorCard color={colors.darkOrange}>
      <CardHeader
        title={
          <Typography variant="h5">
            Comparison of campaign metrics with{' '}
            <Link
              variant="inherit"
              underline="hover"
              href={appLinks.influencers.details.as(
                compared?.influencer_id || '',
              )}
              target="_blank"
            >
              {compared ? getInfluencerName(compared) : 'Compared'}
            </Link>
          </Typography>
        }
      />
      <CardContent>
        <Search query={query} setQuery={setQuery} />
      </CardContent>
    </ColorCard>
  );
};

export default CampaignComparisonCard;
