import React, { useCallback, useState } from 'react';
import {
  TextField,
  debounce,
  ListItem,
  Autocomplete,
  AutocompleteInputChangeReason,
  ListItemText,
  Stack,
  Chip,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { uniq, uniqBy } from 'lodash';
import { AutocompleteSearchItem } from 'api/types/common';
import Loader from 'components/Loader';
import { SelectOption } from 'types/common';
import { getCampaignStatusColor } from 'helpers/getStatusColor';
import { UsersSearchFilterProps } from './index';

const AutocompleteFilter = (props: Omit<UsersSearchFilterProps, 'inline'>) => {
  const { queryFn, queryKey, label, value, onChange, options, defaultOption } =
    props;

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<
    SelectOption | undefined
  >(defaultOption);

  const { data, isLoading } = useQuery(
    [queryKey, inputValue, selectedOption],
    () => queryFn(inputValue, selectedOption),
    { enabled: open, initialData: [] },
  );

  const handleValueChange = useCallback(
    (event: unknown, newValue: AutocompleteSearchItem[] | undefined) => {
      onChange(newValue?.length === 0 ? undefined : newValue);
    },
    [onChange],
  );

  const handleInputChange = debounce(
    (
      event: unknown,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'reset') {
        return;
      }
      setInputValue(newInputValue);
    },
    500,
  );

  return (
    <Stack>
      {options && (
        <Stack direction="row" spacing={1} pb={1}>
          {options.map((option) => (
            <Chip
              sx={{ flex: '1 0 auto' }}
              size="small"
              onClick={() =>
                setSelectedOption(
                  option.value === selectedOption?.value ? undefined : option,
                )
              }
              variant={
                option.value === selectedOption?.value ? 'filled' : 'outlined'
              }
              color={getCampaignStatusColor(option.value as any)}
              label={option.label}
            />
          ))}
        </Stack>
      )}
      <Autocomplete
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        multiple
        clearOnEscape
        disableCloseOnSelect
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value || []}
        onChange={handleValueChange}
        onInputChange={handleInputChange}
        isOptionEqualToValue={(
          option: AutocompleteSearchItem,
          value: AutocompleteSearchItem,
        ) => option._id.$oid === value._id.$oid}
        getOptionLabel={(option: AutocompleteSearchItem) => option.name}
        renderOption={(props1, option) => (
          <ListItem {...props1}>
            <ListItemText
              primary={option.name}
              secondary={
                // eslint-disable-next-line no-nested-ternary
                'status' in option && typeof option.status === 'string'
                  ? option.status
                  : Array.isArray(option.status)
                  ? uniq(option.status).join()
                  : ''
              }
            />
          </ListItem>
        )}
        options={uniqBy(value ? [...value, ...data] : data, 'name') || []}
        loading={isLoading}
        ChipProps={{
          color: 'primary',
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={`Search ${label}`}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              notched: false,
              endAdornment: (
                <>
                  {isLoading && open ? (
                    <Loader circularProgressProps={{ size: 20 }} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
};

export default AutocompleteFilter;
