import React, { useCallback, useMemo, useState } from 'react';
import {
  TextField,
  debounce,
  List,
  ListItem,
  Box,
  ListItemText,
  Checkbox,
  Stack,
  Chip,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { uniq, uniqBy } from 'lodash';
import { AutocompleteSearchItem } from 'api/types/common';
import Loader from 'components/Loader';
import { SelectOption } from 'types/common';
import { getCampaignStatusColor } from 'helpers/getStatusColor';
import { UsersSearchFilterProps } from './index';

const InlineFilter = (props: Omit<UsersSearchFilterProps, 'inline'>) => {
  const {
    queryFn,
    queryKey,
    value,
    onChange,
    label,
    options: filterOptions,
    defaultOption,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState<
    SelectOption | undefined
  >(defaultOption);

  const { data, isLoading } = useQuery(
    [queryKey, inputValue, selectedFilterOption],
    () => queryFn(inputValue, selectedFilterOption),
    { initialData: [] },
  );

  const handleValueChange = useCallback(
    (item: AutocompleteSearchItem) => {
      onChange((prev: AutocompleteSearchItem[] = []) => {
        const value =
          prev?.map((f) => f._id.$oid).indexOf(item._id.$oid) > -1
            ? prev.filter((p) => p._id.$oid !== item._id.$oid)
            : [...prev, item];

        return value.length === 0 ? undefined : value;
      });
    },
    [onChange],
  );

  const handleInputChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInputValue(event.target.value);
    },
    500,
  );

  const options = useMemo(
    () => uniqBy(value ? [...value, ...data] : data, 'name'),
    [data, value],
  );

  return (
    <Box sx={{ p: 2, pt: 0 }}>
      {filterOptions && (
        <Stack
          direction="row"
          spacing={1}
          mt={2}
          sx={{
            position: 'sticky',
            top: 0,
          }}
        >
          {filterOptions.map((option) => (
            <Chip
              sx={{ flex: '1 0 auto' }}
              size="small"
              onClick={() =>
                setSelectedFilterOption(
                  option.value === selectedFilterOption?.value
                    ? undefined
                    : option,
                )
              }
              variant={
                option.value === selectedFilterOption?.value
                  ? 'filled'
                  : 'outlined'
              }
              color={getCampaignStatusColor(option.value as any)}
              label={option.label}
            />
          ))}
        </Stack>
      )}
      <TextField
        autoFocus
        size="small"
        placeholder={`Search ${label}`}
        variant="standard"
        onChange={handleInputChange}
        InputProps={{
          endAdornment: isLoading ? (
            <Loader circularProgressProps={{ size: 20 }} />
          ) : null,
        }}
        sx={(theme) => ({
          mt: filterOptions ? 1 : 2,
          position: 'sticky',
          top: 0,
          backgroundColor: theme.palette.background.paper,
          zIndex: 1,
        })}
      />
      <List>
        {options.map((option, index) => (
          <ListItem
            disableGutters
            disablePadding
            key={option._id?.$oid || index}
            onClick={() => handleValueChange(option)}
            sx={{
              ml: '-9px',
              mr: '9px',
            }}
          >
            <Checkbox
              checked={
                value
                  ? value?.map((f) => f._id?.$oid).indexOf(option._id?.$oid) >
                    -1
                  : false
              }
            />
            <ListItemText
              primary={option.name}
              secondary={
                // eslint-disable-next-line no-nested-ternary
                'status' in option && typeof option.status === 'string'
                  ? option.status
                  : Array.isArray(option.status)
                  ? uniq(option.status).join()
                  : ''
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default InlineFilter;
