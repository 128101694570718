import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { InfluencerTypes } from 'api';
import SearchCard from './SearchCard';
import ComparisonTable from './InfluencerComparisonTable';

interface Props {
  data: InfluencerTypes.ItemData;
}

const DetailsTab = (props: Props): JSX.Element | null => {
  const { data } = props;
  const [comparedInfluencer, setComparedInfluencer] = useState<
    InfluencerTypes.ItemData | undefined
  >();

  return (
    <Stack rowGap={3}>
      <SearchCard
        influencer={data}
        compared={comparedInfluencer}
        setCompared={setComparedInfluencer}
      />
      <ComparisonTable
        influencer={data}
        comparedInfluencer={comparedInfluencer}
      />
    </Stack>
  );
};

export default DetailsTab;
