import React, { ChangeEvent, useCallback } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Grid } from '@mui/material';
import { AssignmentOutlined, EuroOutlined } from '@mui/icons-material';
import { discounts, DiscountTypes } from 'api';
import { FormCard, FormInput } from 'components';
import DISCOUNTS from 'constants/discounts';
import { AutocompleteSearchItem } from 'api/types/common';
import { dataInitialSelectsOptions } from '../fields';

interface Props {
  control: Control<DiscountTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<DiscountTypes.ItemCreateParams>;
  setValue: UseFormSetValue<DiscountTypes.ItemCreateParams>;
  initialSelectsOptions:
    | ReturnType<typeof dataInitialSelectsOptions>
    | undefined;
}

const ProductsBlock = (props: Props): JSX.Element | null => {
  const { control, watch, setValue, initialSelectsOptions } = props;

  const brand = watch('brand');
  const countryTeam = watch('country_team');
  const shopId = watch('shop_id');
  const appliesTo = watch('applies_to');
  const minimumRequirements = watch('minimum_requirements');
  const customerEligibility = watch('customer_eligibility');
  const discountAmountType = watch('discount_amount_type');

  const handleAppliesToChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      const value = event.target.value as DiscountTypes.AppliesToEnum;
      if (value === DiscountTypes.AppliesToEnum.SpecificCollections) {
        setValue('products', []);
      } else {
        setValue('collections', []);
      }

      onChange(value);
    },
    [setValue],
  );

  const handleMinRequirementsChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      setValue('minimum_value', 0);

      onChange(event.target.value);
    },
    [setValue],
  );

  const handleCustomerEligibilityChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      setValue('customer_segments', []);
      setValue('customers_individual', []);

      onChange(event.target.value);
    },
    [setValue],
  );

  const handleAutocompleteChange = useCallback(
    (
      value: AutocompleteSearchItem | AutocompleteSearchItem[] | null,
      onChange: (value: string | string[] | null) => void,
    ) => {
      if (value && Array.isArray(value)) {
        const newValue = value.map((v) => {
          if ('originData' in v) {
            return v.originData;
          }
          return {
            id: v._id.$oid,
            title: v.name,
            image: v.image,
          };
        });

        onChange(newValue);
      }
    },
    [],
  );

  const getCollections = useCallback(
    (query: string) =>
      discounts
        .autocompleteCollections({
          collection_title: query,
          brand,
          shop_id: shopId,
          country_team: countryTeam,
        })
        .then((res) =>
          res.data.collections.edges.map((e) => ({
            name: e.node.title,
            _id: { $oid: e.node.id },
            image: e.node.image?.url || '',
            originData: {
              id: e.node.id,
              title: e.node.title,
              image: e.node.image ? e.node.image.url : '',
            },
          })),
        ),
    [brand, countryTeam, shopId],
  );

  const getProducts = useCallback(
    (query: string) =>
      discounts
        .autocompleteProducts({
          product_title: query,
          brand,
          shop_id: shopId,
          country_team: countryTeam,
        })
        .then((res) =>
          res.data.products.edges.map((e) => ({
            name: e.node.title,
            _id: { $oid: e.node.id },
            image: e.node.images?.edges[0]?.node.url,
            originData: {
              id: e.node.id,
              title: e.node.title,
              image: e.node.images?.edges[0]?.node.url,
            },
          })),
        ),
    [brand, countryTeam, shopId],
  );

  const getCustomersSegments = useCallback(
    (query: string) =>
      discounts
        .autocompleteCustomerSegments({
          customer_list_name: query,
          brand,
          shop_id: shopId,
          country_team: countryTeam,
        })
        .then((res) =>
          res.data.segments.edges.map((e) => ({
            name: e.node.name,
            _id: { $oid: e.node.id },
            originData: {
              id: e.node.id,
              name: e.node.name,
            },
          })),
        ),
    [brand, countryTeam, shopId],
  );

  const getCustomersIndividual = useCallback(
    (query: string) =>
      discounts
        .autocompleteCustomerEmails({
          customer_email: query,
          brand,
          shop_id: shopId,
          country_team: countryTeam,
        })
        .then((res) =>
          res.data.customers.edges.map((e) => ({
            name: e.node.email,
            _id: { $oid: e.node.id },
            originData: e.node,
          })),
        ),
    [brand, countryTeam, shopId],
  );

  return (
    <FormCard title="Products details" avatar={<AssignmentOutlined />}>
      <Grid container spacing={2}>
        {discountAmountType ===
          DiscountTypes.DiscountAmountTypeEnum.ProductDiscount && (
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="applies_to"
              inputType="radio"
              label="Applies to"
              options={DISCOUNTS.APPLIES_TO_OPTIONS}
              onChange={handleAppliesToChange}
              tooltip="After changing this field selected products and collections will be reset"
              rules={{ required: true }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {appliesTo === DiscountTypes.AppliesToEnum.SpecificCollections && (
            <FormInput
              control={control}
              name="collections"
              inputType="autocompleteSearch"
              label="Collections"
              multiple
              disabled={!shopId}
              initialValue={initialSelectsOptions?.collections}
              onChange={handleAutocompleteChange}
              queryKey={discounts.endpoints.autocompleteCollections}
              queryFn={getCollections}
              rules={{
                required:
                  appliesTo === DiscountTypes.AppliesToEnum.SpecificCollections,
              }}
            />
          )}
          {appliesTo === DiscountTypes.AppliesToEnum.SpecificProducts && (
            <FormInput
              control={control}
              name="products"
              inputType="autocompleteSearch"
              label="Products"
              multiple
              disabled={!shopId}
              initialValue={initialSelectsOptions?.products}
              onChange={handleAutocompleteChange}
              queryKey={discounts.endpoints.autocompleteProducts}
              queryFn={getProducts}
              rules={{
                required:
                  appliesTo === DiscountTypes.AppliesToEnum.SpecificProducts,
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormInput
            control={control}
            name="minimum_requirements"
            inputType="radio"
            label="Minimum requirements"
            tooltip={`After changing this field "Min value" field will be reset`}
            options={DISCOUNTS.MINIMUM_REQUIREMENTS_OPTIONS}
            onChange={handleMinRequirementsChange}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12}>
          {minimumRequirements !==
            DiscountTypes.MinimumRequirementsEnum.None && (
            <FormInput
              control={control}
              name="minimum_value"
              inputType="number"
              label="Min value"
              InputProps={{
                endAdornment:
                  minimumRequirements ===
                  DiscountTypes.MinimumRequirementsEnum
                    .MinimumPurchaseAmount ? (
                    <EuroOutlined />
                  ) : undefined,
              }}
              rules={{
                required: true,
                min: 1,
                onBlur: (e) =>
                  setValue('minimum_value', +(+e.target.value).toFixed(2)),
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormInput
            control={control}
            name="customer_eligibility"
            inputType="radio"
            label="Customer eligibility"
            tooltip={`After changing this field "Customers" and "Customer segments" fields will be reset`}
            options={DISCOUNTS.CUSTOMER_ELIGIBILITY_OPTIONS}
            onChange={handleCustomerEligibilityChange}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12}>
          {customerEligibility ===
            DiscountTypes.CustomerEligibilityEnum.SpecificCustomerSegments && (
            <FormInput
              control={control}
              name="customer_segments"
              inputType="autocompleteSearch"
              label="Customer segments"
              multiple
              disabled={!shopId}
              initialValue={initialSelectsOptions?.customerSegments}
              onChange={handleAutocompleteChange}
              queryKey={discounts.endpoints.autocompleteCustomerSegments}
              queryFn={getCustomersSegments}
              rules={{
                required:
                  customerEligibility ===
                  DiscountTypes.CustomerEligibilityEnum
                    .SpecificCustomerSegments,
              }}
            />
          )}
          {customerEligibility ===
            DiscountTypes.CustomerEligibilityEnum.SpecificCustomers && (
            <FormInput
              control={control}
              name="customers_individual"
              inputType="autocompleteSearch"
              label="Customers"
              multiple
              disabled={!shopId}
              initialValue={initialSelectsOptions?.customersIndividual}
              onChange={handleAutocompleteChange}
              queryKey={discounts.endpoints.autocompleteCustomerEmails}
              queryFn={getCustomersIndividual}
              rules={{
                required:
                  customerEligibility ===
                  DiscountTypes.CustomerEligibilityEnum.SpecificCustomers,
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormInput
            control={control}
            name="usage_limit"
            inputType="number"
            label="Usage limit"
            helperText="Number of times this discount can be used in total (leave blank if no limit)"
            rules={{
              onBlur: (e) =>
                setValue(
                  'usage_limit',
                  +e.target.value === 0 ? null : +(+e.target.value).toFixed(2),
                ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            control={control}
            name="applies_once_per_customer"
            inputType="switch"
            label="Apply discount once per customer"
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default ProductsBlock;
