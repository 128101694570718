import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Typography,
  Tooltip,
} from '@mui/material';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { InfoOutlined } from '@mui/icons-material';
import FormInputProps from './FormInputProps';

type Props<T extends FieldValues = any> = Omit<
  FormInputProps.RadioInput,
  'inputType'
> &
  UseControllerProps<T>;

const RadioInput: React.FC<Props> = (props: Props) => {
  const {
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    label,
    options,
    onChange,
    FormLabelProps,
    FormControlProps,
    tooltip,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FormControl {...FormControlProps} error={!!error}>
      <FormLabel {...FormLabelProps} id={field.name}>
        {tooltip ? (
          <Typography display="flex" gap={1}>
            {label}
            <Tooltip title={tooltip}>
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </Typography>
        ) : (
          label
        )}
      </FormLabel>
      <RadioGroup
        {...rest}
        ref={field.ref}
        name={field.name}
        value={field.value || ''}
        onChange={
          onChange ? (e) => onChange(e, field.onChange) : field.onChange
        }
        onBlur={field.onBlur}
      >
        {options.map((o) => (
          <FormControlLabel
            key={o.value}
            value={o.value}
            control={<Radio />}
            label={o.label}
            disabled={o.disabled}
          />
        ))}
      </RadioGroup>
      {!!error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default RadioInput;
