import React from 'react';
import {
  AutocompleteSearchItem,
  AutocompleteSearchResponse,
} from 'api/types/common';
import { HandleChangeValue } from 'hooks';
import { SelectOption, SelectOptions } from 'types/common';
import InlineFilter from './InlineFilter';
import AutocompleteFilter from './AutocompleteFilter';

type Value = AutocompleteSearchItem[] | undefined;

export interface UsersSearchFilterProps {
  label: string;
  value: Value;
  onChange: (value: HandleChangeValue<Value>) => void;
  inline?: boolean;

  queryKey: string;
  queryFn: (query: string, option?: SelectOption) => AutocompleteSearchResponse;
  defaultOption?: SelectOption;
  options?: SelectOptions;
}

const UsersSearchFilter = (props: UsersSearchFilterProps): JSX.Element => {
  const { inline, ...rest } = props;

  if (inline) {
    return <InlineFilter {...rest} />;
  }

  return <AutocompleteFilter {...rest} />;
};

export default UsersSearchFilter;
