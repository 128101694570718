import React, { useCallback, useMemo } from 'react';
import {
  alpha,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatCurrency, formatPercent } from 'helpers/formatters';
import { CampaignTypes } from 'api';
import { getSum } from 'helpers/calculations';

interface Props {
  performance: CampaignTypes.PerformanceInsightsVarData;
  performanceCompared: CampaignTypes.PerformanceInsightsVarData;
  campaign: CampaignTypes.ItemData;
  campaignCompared: CampaignTypes.ItemData;
}

const config = [
  { key: 'strategy', label: 'Channel' },
  { key: 'sum_revenue_potential', label: 'Potential Revenue' },
  { key: 'sum_revenue_actual', label: 'Actual Revenue' },
  { key: 'variance', label: 'Variance' },
  { key: 'contribution_potential', label: 'Contribution Potential' },
  { key: 'contribution_actual', label: 'Contribution Actual' },
];

const alignData = (
  base: CampaignTypes.PerformanceInsightsVarData,
  compare: CampaignTypes.PerformanceInsightsVarData,
) => {
  const strategies = Array.from(
    new Set(...[base.map((b) => b.strategy), compare.map((c) => c.strategy)]),
  );

  const data = strategies.map((strategy) => ({
    base: base.find((v) => v.strategy === strategy),
    compare: compare.find((v) => v.strategy === strategy),
    strategy,
  }));

  return { data, strategies };
};

const CardTable: React.FC<Props> = (props) => {
  const { campaignCompared, performanceCompared, performance, campaign } =
    props;

  const { data: alignedData } = useMemo(
    () => alignData(performance, performanceCompared),
    [performance, performanceCompared],
  );

  const getValue = useCallback(
    (
      item: CampaignTypes.PerformanceInsightsVarItem | undefined,
      performanceData: CampaignTypes.PerformanceInsightsVarData,
      c: (typeof config)[0],
    ) => {
      if (!item) return '---';

      switch (c.key) {
        case 'strategy':
          return item[c.key];
        case 'sum_revenue_actual':
        case 'sum_revenue_potential':
          return formatCurrency(item[c.key] as number);
        case 'variance':
          return formatPercent(item[c.key] || 0);
        case 'contribution_potential':
          return formatPercent(
            (item.sum_revenue_potential /
              getSum(performanceData.map((p) => p.sum_revenue_potential))) *
              100,
          );
        case 'contribution_actual':
          return formatPercent(
            (item.sum_revenue_actual /
              getSum(performanceData.map((p) => p.sum_revenue_actual))) *
              100,
          );
        default:
          return '-';
      }
    },
    [],
  );

  const getSxProps = (index: number, theme: any) => ({
    borderRight: index === 5 ? `1px solid ${theme.palette.divider}` : undefined,
  });

  return (
    <Card sx={{ overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              colSpan={config.length}
              align="center"
              sx={(theme) => ({
                borderRight: `1px solid ${theme.palette.divider}`,
                background: alpha(theme.palette.primary.main, 0.15),
                color: theme.palette.primary.main,
              })}
            >
              {campaign.name}
            </TableCell>
            <TableCell
              colSpan={config.length}
              align="center"
              sx={(theme) => ({
                background: alpha(theme.palette.secondary.main, 0.15),
                color: theme.palette.secondary.main,
              })}
            >
              {campaignCompared.name}
            </TableCell>
          </TableRow>
          <TableRow>
            {config.map((c, index) => (
              <TableCell key={c.key} sx={(theme) => getSxProps(index, theme)}>
                {c.label}
              </TableCell>
            ))}
            {config.map((c, index) => (
              <TableCell key={c.key} sx={(theme) => getSxProps(index, theme)}>
                {c.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {alignedData.map(({ base, compare, strategy }) => (
            <TableRow key={strategy}>
              {config.map((c, index) => (
                <TableCell key={c.key} sx={(theme) => getSxProps(index, theme)}>
                  {getValue(base, performance, c)}
                </TableCell>
              ))}
              {config.map((c, index) => (
                <TableCell key={c.key} sx={(theme) => getSxProps(index, theme)}>
                  {getValue(compare, performanceCompared, c)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default CardTable;
