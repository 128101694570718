import React, { ChangeEvent, useCallback } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Grid } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { DealTypes } from 'api';
import DEALS from 'constants/deals';
import { arrayToOptions } from 'helpers/common';
import { FormCard, FormInput } from 'components';
import { MarketingTypes } from 'api/marketing';
import dayjs from 'dayjs';

interface Props {
  control: Control<MarketingTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<MarketingTypes.ItemCreateParams>;
  setValue: UseFormSetValue<MarketingTypes.ItemCreateParams>;
}

const PlatformBlock = (props: Props): JSX.Element | null => {
  const { control, watch, setValue } = props;

  const strategy = watch('strategy');
  const startDate = watch('start_date');
  const endDate = watch('end_date');

  const handleStrategyChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      const value = event.target.value as DealTypes.StrategiesEnum;
      const channelOptions = DEALS.CHANNEL_OPTIONS[value];

      setValue('channel', channelOptions[0]);

      onChange(value);
    },
    [setValue],
  );

  return (
    <FormCard title="Platform details" avatar={<InfoOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="strategy"
            inputType="select"
            label="Channel"
            tooltip="After changing the channel, subchannel field value will be changed to first item in subchannels list"
            options={DEALS.STRATEGY_OPTIONS}
            onChange={handleStrategyChange}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="channel"
            inputType="select"
            label="Sub channel"
            options={arrayToOptions(DEALS.CHANNEL_OPTIONS[strategy])}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="datepicker"
            name="start_date"
            label="Start Date"
            rules={{
              required: true,
              onChange: (event) => {
                const date = dayjs.utc(event.target.value);
                if (date.isAfter(endDate)) {
                  const start = date.format('DD/MM/YYYY');
                  const end = dayjs(endDate).format('DD/MM/YYYY');
                  if (!dayjs(start).isSame(end)) {
                    setValue('end_date', null);
                  }
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            inputType="datepicker"
            name="end_date"
            label="End Date"
            minDate={dayjs.utc(startDate)}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default PlatformBlock;
