import { Navigate, useSearchParams } from 'react-router-dom';
import { useStoreContext } from 'store';
import { appLinks } from './links';

interface Props {
  children: JSX.Element;
}
// http://localhost:3000/influencers/pgock/edit
// http://localhost:3000/influencers?influencerId=%22mis1j%22
// todo - opening before sign in wont work, redirect will remove params from url http://localhost:3000/influencers?size=%5B%22Celebrity%22%5D&personal_info.gender=%5B%22Female%22%5D&influencerId=%22alq3v%22
const PublicRoute = (props: Props): JSX.Element => {
  const { children } = props;
  const {
    state: { user },
  } = useStoreContext();

  const [params] = useSearchParams();
  const path = params.get('redirectTo');
  const redirectTo = path ? decodeURI(path) : '';

  if (user) {
    return <Navigate replace to={redirectTo || appLinks.influencers.link} />;
  }

  return children;
};

export default PublicRoute;
