/* eslint-disable no-param-reassign */

import React, { useCallback } from 'react';
import { TextField, Tooltip, Typography } from '@mui/material';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { InfoOutlined } from '@mui/icons-material';
import FormInputProps from './FormInputProps';

type Props<T extends FieldValues = any> = Omit<
  FormInputProps.TextInput,
  'inputType'
> &
  UseControllerProps<T>;

const TextInput: React.FC<Props> = (props: Props) => {
  const {
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    onChange,
    type,
    helperText,
    errorText,
    error: inputError,
    label,
    tooltip,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(event, field.onChange);
      } else if (type === 'number') {
        const value = event.target.value ? +event.target.value : 0;
        field.onChange(value);

        // trick to avoid issue that keeps input value & field value different ex. field === 0 and input === 000
        event.target.value = value as unknown as string;

        // trick to avoid issue that keeps moves cursor to start after deleting chars after comma
        // @ts-ignore
        event.target.type = 'text';
        event.target.setSelectionRange(-1, -1);
        // @ts-ignore
        event.target.type = 'number';
      } else {
        field.onChange(event.target.value);
      }
    },
    [field, onChange, type],
  );

  return (
    <TextField
      {...rest}
      label={
        tooltip ? (
          <Typography display="flex" gap={1}>
            {label}
            <Tooltip title={tooltip}>
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </Typography>
        ) : (
          label
        )
      }
      placeholder={
        rest.placeholder ||
        (typeof label === 'string' ? `Enter ${label.toLowerCase()}` : undefined)
      }
      variant="outlined"
      type={type}
      inputRef={field.ref}
      name={field.name}
      value={field.value ?? ''}
      onChange={handleChange}
      onBlur={field.onBlur}
      error={!!error || inputError}
      helperText={error ? error.message || errorText : helperText}
      InputProps={{
        ...rest.InputProps,
        notched: false,
        inputProps: {
          min: rules?.min,
          ...rest.InputProps?.inputProps,
        },
      }}
    />
  );
};

export default TextInput;
